import PropTypes from "prop-types"
import React, { useState } from "react"
import NavLink from "./NavLink"
import { Link } from "gatsby"
import { Navbar, NavbarToggler, Collapse, Nav } from "reactstrap"
const Header = ({ siteTitle }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <Navbar
      className="navbar navbar-light fixed-top navbar-shrink"
      expand="md"
      id="mainNav"
    >
      <div className="container">
        <Link className="navbar-brand js-scroll-trigger" to="/">
          {siteTitle}
        </Link>

        <NavbarToggler
          className="navbar-toggler navbar-toggler-right"
          onClick={toggle}
        >
          <span className="navbar-toggler-icon"></span>
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/about">Meet Mike</NavLink>
            <NavLink to="/corporate">Corporate Partnership</NavLink>
            <NavLink to="/additional-videos">Additional Videos</NavLink>
            <NavLink to="/contact">Contact</NavLink>
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
