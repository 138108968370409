import React from "react"
import { Link } from "gatsby"

export default function NavLink({ to, children }) {
  return (
    <li className="nav-item">
      <Link
        className="nav-link js-scroll-trigger"
        activeClassName="active"
        to={to}
      >
        {children}
      </Link>
    </li>
  )
}
